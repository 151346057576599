<template>
	<el-container>
		<router-view/>
	</el-container>
</template>

<script>
export default {
  name: 'pro',
  components: {
  }
}
</script>
<style>
</style>
